export default {
    // Home
    home: {
        title: 'Standorte',
        introduction: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.'
    },

    // Device
    device: {
        back: 'Zurück',
        filterTitle: 'Daten filtern',

        information: {
            title: 'Informationen',
            type: 'Typ',
            sensor: 'Parameter',
            station: 'Station',
            unit: 'Einheit',
            dataWindow: 'Verfügbarer Datenbereich'
        },

        lastMeasurement: {
            title: 'Letzte Messung',
        },

        types: {
            nw: 'Grundwassermessstelle',
            ow: 'Oberflächengewässermessstelle',
        },

        // Bundled by channels
        channels: {
            oberflaechengewaesser_ott: {
                pegel_muM: {
                    name: 'Oberflächenpegel',
                    scale: 'm ü. M.',
                    label: 'Oberflächenpegel',
                },
                temperatur_c: {
                    name: 'Temperatur',
                    scale: '°C',
                    label: 'Temperatur',
                },
                el_leit_muScm: {
                    name: 'Elektrische Leitfähigkeit',
                    scale: 'µS/cm',
                    label: 'Elektrische Leitfähigkeit',
                },
            },
            oberflaechengewaesser_ht: {
                pegel_m: {
                    name: 'Oberflächenpegel',
                    scale: 'm',
                    label: 'Oberflächenpegel',
                },
                temperatur_c: {
                    name: 'Temperatur',
                    scale: '°C',
                    label: 'Temperatur',
                },
                el_leit_mScm: {
                    name: 'Elektrische Leitfähigkeit',
                    scale: 'mS/cm',
                    label: 'Elektrische Leitfähigkeit',
                }
            },
            grundwasser: {
                pegel_muM: {
                    name: 'Pegel',
                    scale: 'm ü. M.',
                    label: 'Pegel',
                },
                temperatur_c: {
                    name: 'Temperatur',
                    scale: '°C',
                    label: 'Temperatur',
                },
                el_leit_mScm: {
                    name: 'Elektrische Leitfähigkeit',
                    scale: 'mS/cm',
                    label: 'Elektrische Leitfähigkeit',
                }
            }
        },
    },

    // Components
    searchFilter: {
        title: 'Standorte',
        placeholder: 'Filtern...',
        clearSearch: 'Filter zurücksetzen'
    },

    offlineFilter: {
        label: 'Offline',
    },

    typeFilter: {
        labelNw: 'Grundwassermessstellen',
        labelOw: 'Oberflächengewässermessstellen',
    },

    deviceList: {
        footer: '{count} Geräte',
        next: 'Nächste Seite',
        prev: 'Letzte Seite',
    },

    map: {
        layer: 'Karte',
        attribution: '© geo.admin.ch',

        pin: {
            blue: 'Oberflächengewässermessstellen',
            red: 'Grundwassermessstellen',
            gray: 'Offline'
        }
    },

    export: {
        title: 'Daten-Export',
        download: 'Download',
        print: 'Drucken',
        time: 'Zeitstempel [RFC 3339]',
    },

    plot: {
        time: {
            label: 'Datum'
        }
    }
};
