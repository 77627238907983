// Styles
import '@/scss/app.scss'
import 'leaflet/dist/leaflet.css'
import 'uplot/dist/uPlot.min.css'

// Dependencies
import Vue from 'vue'
import VueAxios from 'vue-axios'
import VueRouter from 'vue-router'
import VueI18n from 'vue-i18n'
import Vuex from 'vuex'
import '@tabler/core';

// Config
import routes from '@/config/routes';
import axios from '@/config/axios';

// Fix Leaflet icons
import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Store
import store from '@/store/store';

// Services
import i18nFactory from '@/services/i18n-factory';

// App
import App from '@/App.vue'

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(VueAxios, axios)
Vue.use(VueI18n)
Vue.use(Vuex)

const i18n = i18nFactory.create();

const router = new VueRouter({
    mode: 'hash',
    routes,
})

new Vue({
    render: h => h(App),
    i18n,
    router,
    store,
}).$mount('#app')
