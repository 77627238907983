<template>
    <div class="map" v-bind:class="{ 'map--mini': theme === 'mini' }">
        <l-map :zoom="zoom" :maxZoom="15" :center="center" :bounds="bounds" v-if="!loading" @update:zoom="onZoom" @ready="updatePrintImage" ref="map">
            <!-- Layers -->
            <l-w-m-s-tile-layer v-for="layer in layers"
               :key="layer.name"
               :base-url="layer.baseUrl"
               :layers="layer.layers"
               :visible="layer.visible"
               :name="layer.name"
               :attribution="layer.attribution"
               :version="layer.version"
               layer-type="base"
            ></l-w-m-s-tile-layer>

            <!-- Markers -->
            <l-marker v-for="marker in getMarkers(devices)"
                :key="marker.id"
                :lat-lng="marker.device.coordinates"
                :icon="marker.icon"
                :options="{ device: marker.device }"
                @ready="onMarkerReady"
            >
                <!-- Popups -->
                <l-popup v-if="theme !== 'mini'">
                    <h4 class="strong">{{ marker.device.name }}</h4>

                    <div v-for="(field, index) in marker.device.fields" :key="index">
                        <router-link :to="{ name: 'device', params: { id: marker.device.id, field: field.name }}">
                            <component :is="getFieldIcon(field.name)"></component>
                            {{ $t(`device.channels.${marker.device.channel}.${field.name}.name`) }}
                        </router-link>

                        <span class="text-muted" v-if="field.lastMeasurement">
                            {{ field.lastMeasurement.value }} {{ field.scale }}
                            ({{ dateFormat(field.lastMeasurement.date) }})
                        </span>
                    </div>

                </l-popup>
            </l-marker>
        </l-map>

        <div class="skeleton-image" v-else></div>

        <div class="text-center mt-2" v-if="theme !== 'mini'">
            <span class="me-3"><img :src="pins.blue" alt=""> {{ $t('map.pin.blue') }}</span>
            <span class="me-3"><img :src="pins.red" alt=""> {{ $t('map.pin.red') }}</span>
            <span class="me-3"><img :src="pins.gray" alt=""> {{ $t('map.pin.gray') }}</span>
        </div>
    </div>
</template>

<script>
import { icon, latLngBounds } from 'leaflet';
import { LMap, LMarker, LPopup, LWMSTileLayer } from 'vue2-leaflet';
import leafletImage from 'leaflet-image';
import { TYPE_OW } from '@/services/device-transformer';
import { getIcon } from "@/services/field-config";
import { dateFormat } from "@/services/date-formatter";

export default {
    name: 'Map',
    components: {
        LMap, LWMSTileLayer, LMarker, LPopup
    },
    props: {
        devices: Array,
        loading: Boolean,
        theme: String,
    },
    data() {
        return {
            zoom: 13,
            center: [46.5, 9.31],
            layers: [
                {
                    name: this.$t('map.layer'),
                    baseUrl: process.env.VUE_APP_WMS_URL,
                    version: process.env.VUE_APP_WMS_VERSION,
                    visible: true,
                    format: 'image/png',
                    layers: 'ch.swisstopo.pixelkarte-grau',
                    transparent: true,
                    attribution: this.$t('map.attribution'),
                },
            ],

            pins: {
                blue: process.env.VUE_APP_ASSETS_LOCATION + 'pin-blue.svg',
                red: process.env.VUE_APP_ASSETS_LOCATION + 'pin-red.svg',
                gray: process.env.VUE_APP_ASSETS_LOCATION + 'pin-gray.svg',
            }
        }
    },

    computed: {
        bounds() {
            if (0 === this.devices.length) {
                return null;
            }

            return latLngBounds(this.devices.map(device => {
                return device.coordinates;
            }));
        }
    },

    methods: {
        getFieldIcon: getIcon,
        dateFormat,

        updatePrintImage() {
            const printContainer = document.getElementById('print-map');

            if (!printContainer) {
                return;
            }

            const map = this.$refs.map.mapObject;

            leafletImage(map, function(err, canvas) {
                const img = document.createElement('img');
                const dimensions = map.getSize();

                img.width = dimensions.x;
                img.height = dimensions.y;
                img.src = canvas.toDataURL();

                printContainer.innerHTML = '';
                printContainer.appendChild(img);
            });
        },

        getMarkers(devices) {
            return devices.map(device => {
                return {
                    id: device.id,
                    icon: this.getIcon(device),
                    device
                }
            });
        },

        getIcon(device) {
            if (device.offline) {
                return icon({
                    iconUrl: process.env.VUE_APP_ASSETS_LOCATION + 'pin-gray.svg',
                    iconSize: [17, 20],
                    iconAnchor: [8, 20],
                    popupAnchor: [0, -20],
                });
            }

            const sizer = this.theme === 'mini' ? 2 : 1;

            return icon({
                iconUrl: device.type === TYPE_OW ? process.env.VUE_APP_ASSETS_LOCATION + 'pin-blue.svg' : process.env.VUE_APP_ASSETS_LOCATION + 'pin-red.svg',
                iconSize: [17 * sizer, 20 * sizer],
                iconAnchor: [8 * sizer, 20 * sizer],
                popupAnchor: [0, -20 * sizer],
            });
        },

        onMarkerReady(marker) {
            marker.on('mouseover', () => {
                this.$emit('open-popup', marker.options.device);

                marker.openPopup();
            });
        },

        onZoom(zoom) {
            this.updatePrintImage();

            if (this.theme === 'mini') {
                return;
            }

            const layers = this.$refs.map.mapObject._layers;
            const layerKeys = Object.keys(layers);

            layerKeys.forEach(layerKey => {
                if (!layers[layerKey]._icon) {
                    return;
                }

                const layer = layers[layerKey];
                const icon = layer.options.icon;

                let sizer = 1;

                if (zoom > 9) {
                    sizer = 1.5;
                }

                if (zoom > 11) {
                    sizer = 2;
                }

                const size = [17 * sizer, 20 * sizer];
                const anchor = [8 * sizer, 20 * sizer];

                icon.options.iconSize = size;
                icon.options.iconAnchor = anchor;

                layers[layerKey].setIcon(icon);
            });
        }
    }
}
</script>
