<template>
    <div class="card mb-3">
        <div class="card-body">
            <h4 class="card-title">{{ $t('device.information.title') }}</h4>

            <div class="mb-2">
                <small class="text-muted text-truncate">{{ $t('device.information.type') }}</small>
                <div class="text-body d-block text-truncate">{{ $t(getDeviceTypeName(device)) }}</div>
            </div>

            <div class="mb-2">
                <small class="text-muted text-truncate">{{ $t('device.information.sensor') }}</small>
                <div class="text-body d-block text-truncate">{{ $t(`device.channels.${device.channel}.${field.name}.name`) }}</div>
            </div>

            <div class="mb-2">
                <small class="text-muted text-truncate">{{ $t('device.information.unit') }}</small>
                <div class="text-body d-block text-truncate">{{ field.scale }}</div>
            </div>

            <div class="mb-2">
                <small class="text-muted text-truncate">{{ $t('device.information.station') }}</small>
                <div class="text-body d-block text-truncate">{{ device.name }}</div>
            </div>

            <div class="mb-2">
                <small class="text-muted text-truncate">{{ $t('device.information.dataWindow') }}</small>
                <div class="text-body d-block text-truncate">{{ dateRange }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { getDeviceTypeName } from "@/services/device-transformer";
import { dateFormat } from "@/services/date-formatter";

export default {
    name: 'DeviceInfo',
    props: {
        device: Object,
        field: Object,
    },

    computed: {
        dateRange() {
            const installed = this.device.installed;
            const uninstalled = this.device.uninstalled ? this.device.uninstalled : new Date();

            return `${dateFormat(installed)} - ${dateFormat(uninstalled)}`;
        }
    },

    methods: {
        getDeviceTypeName,
    }
};
</script>
