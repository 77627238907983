import Axios from 'axios'

export default Axios.create({
    baseURL: process.env.VUE_APP_API_ENDPOINT,
    headers: {
        common: {
            'Authorization': `Bearer ${process.env.VUE_APP_API_KEY}`,
        }
    }
});
