<template>
    <div>
        <label class="form-check mb-1">
            <input class="form-check-input" type="checkbox" :disabled="loading" :checked="value[TYPE_OW]" @input="$emit('filter', { type: TYPE_OW, value: $event.target.checked })">
            <span class="form-check-label">{{ $t('typeFilter.labelOw') }}</span>
        </label>
        <label class="form-check mb-0">
            <input class="form-check-input" type="checkbox" :disabled="loading" :checked="value[TYPE_NW]" @input="$emit('filter', { type: TYPE_NW, value: $event.target.checked })">
            <span class="form-check-label">{{ $t('typeFilter.labelNw') }}</span>
        </label>
    </div>
</template>

<script>
import { TYPE_NW, TYPE_OW } from '@/services/device-transformer';

export default {
    props: {
        value: Object,
        loading: Boolean,
    },

    data: () => ({
        TYPE_NW,
        TYPE_OW,
    }),
}
</script>
