<template>
    <div id="page-device">
        <div class="d-none d-print-block">
            <img :src="getPrintLogo()" alt="ANU Logo" height="75" width="258" class="mb-3">
        </div>

        <div class="page-header" v-if="device">
            <div class="row align-items-top">
                <div class="col">
                    <h1>{{ device.name }}</h1>
                    <h2 v-if="field" class="d-none d-print-block">{{ $t(`device.channels.${device.channel}.${field.name}.name`) }}</h2>
                    <h3>{{ $t(getDeviceTypeName(device)) }}</h3>
                </div>
                <div class="col-auto ms-auto d-none d-print-block">
                    <div id="print-map"></div>
                </div>

                <div class="col-auto ms-auto d-print-none">
                    <div class="btn-list">
                        <router-link :to="{ name: 'home' }" class="btn btn-primary d">
                            <ArrowLeftIcon />

                            {{ $t('device.back') }}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div class="row" v-if="device">
                <div class="print-order-2 col-lg-8">
                    <div class="card-tabs mb-3">
                        <ul class="nav nav-tabs d-print-none" v-if="device && !device.fieldsLoading">
                            <li v-for="(availableField, index) in device.fields" :key="index" class="nav-item">
                                <router-link :to="{ name: 'device', params: { id: device.id, field: availableField.name }}" class="nav-link" :class="{ 'disabled': field.loading, 'active': availableField.name === field.name }">
                                    <div v-if="field.loading" class="spinner-border spinner-border-sm text-muted me-2" role="status"></div>
                                    <component v-else :is="getIcon(availableField.name)"></component>

                                    {{ $t(`device.channels.${device.channel}.${availableField.name}.name`) }}
                                </router-link>
                            </li>
                        </ul>

                        <div class="tab-content">
                            <div class="card tab-pane active">
                                <div v-if="field" class="card-body" data-plot-container>
                                    <Plot :title="$t(`device.channels.${device.channel}.${field.name}.name`)" :data="field.data" :loading="field.loading" :scale="field.scale" :label="field.label"></Plot>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" v-if="field">
                        <div class="col">
                            <DeviceInfo :field="field" :device="device"></DeviceInfo>
                        </div>
                        <div class="col">
                            <LastMeasurement :field="field" :device="device"></LastMeasurement>
                        </div>
                    </div>
                </div>
                <div class="print-order-1 col-lg-4">
                    <div class="border-0 mb-3">
                        <div class="card">
                            <div class="card-body d-print-none">
                                <Map :devices="[device]" :loading="false" theme="mini"></Map>
                            </div>
                        </div>
                    </div>

                    <div class="d-print-none">
                        <DataFilter :from="filter.from" :to="filter.to" :device="device" v-on:changeFilter="onChangeFilter"></DataFilter>

                        <div v-if="field">
                            <Export :data="field.data" :device="device" :field="$t(`device.channels.${device.channel}.${field.name}.name`)" :scale="field.scale"></Export>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/config/axios'
import Plot from '@/components/Plot'
import Map from '@/components/Map'
import DataFilter from '@/components/DataFilter'
import Export from '@/components/Export'
import { transform, getDeviceTypeName } from '@/services/device-transformer';
import ArrowLeftIcon from "@/components/icons/ArrowLeftIcon";
import DeviceInfo from "@/components/DeviceInfo";
import LastMeasurement from "@/components/LastMeasurement";
import { getIcon, getAvailableFields, getAvailableInternalKeys, convertName, reverseConvertName } from "@/services/field-config";

export default {
    name: 'Device',
    components: {
        LastMeasurement,
        DeviceInfo,
        ArrowLeftIcon,
        Plot,
        DataFilter,
        Map,
        Export,
    },

    data: () => ({
        id: null,
        field: null,

        device: null,
        fields: [],

        filter: {
            from: null,
            to: null,
        },
    }),

    mounted() {
        const id = this.$route.params.id;
        const field = this.$route.params.field;

        this.id = id;

        if (this.getAvailableFields().indexOf(field) < 0) {
            this.$router.push('404');

            return;
        }

        axios
            .get(`/device/${id}`)
            .then(response => {
                this.device = transform(response.data);
                this.device.fieldsLoading = true;

                const installationDate = new Date(this.device.installed);
                const to = new Date();
                let from = new Date(+to);
                from.setDate(to.getDate() - (30));

                if (installationDate > from) {
                    from = installationDate;
                }

                this.filter = {
                    from: from,
                    to: to,
                }

                axios
                    .get(`/device/${id}/fields`)
                    .then(response => {
                        const data = response.data;

                        const filteredFields = this.getAvailableInternalKeys().filter(value => data.includes(value));
                        const fields = [];

                        filteredFields.forEach(field => {
                            const fieldName = this.convertName(field);

                            fields.push({
                                name: fieldName,
                                internalKey: field,
                                scale: this.$t(`device.channels.${this.device.channel}.${fieldName}.scale`),
                                lastMeasurement: null
                            });
                        });

                        this.device.fields = fields;
                        this.device.fieldsLoading = false;

                        if (!data.includes(reverseConvertName(field))) {
                            // No data
                            return;
                        }

                        this.field = {
                            name: field,
                            internalKey: reverseConvertName(field),
                            data: [],
                            loading: true,
                            scale: this.$t(`device.channels.${this.device.channel}.${field}.scale`),
                            label: this.$t(`device.channels.${this.device.channel}.${field}.label`),
                        }

                        this.updateData();
                    });
            });
    },

    watch: {
        filter: {
            handler() {
                if (!this.field) {
                    return;
                }

                if (!this.device) {
                    return;
                }

                this.updateData();
            },
            deep: true,
        }
    },

    methods: {
        getAvailableInternalKeys,
        getAvailableFields,
        convertName,
        getIcon,
        getDeviceTypeName,

        getPrintLogo() {
            return process.env.VUE_APP_ASSETS_LOCATION + 'logo-anu.jpg';
        },

        updateData() {
            const id = this.device.id;

            this.field.loading = true;
            this.field.data = null;

            axios
                .get(`/device/${id}/data/timeseries?fields=${this.field.internalKey}&start=${this.filter.from.toISOString()}&end=${this.filter.to.toISOString()}`)
                .then(response => {
                    const fieldsData = response.data;

                    fieldsData.forEach(fieldData => {
                        const timestamps = fieldData.timestamps;
                        const y = fieldData.values;

                        const x = timestamps.map(dateString => {
                            const date = new Date(dateString);

                            return date.getTime() / 1000;
                        });

                        this.field.data = [x, y];
                        this.field.loading = false;
                    });
                });
        },

        onChangeFilter(value) {
            this.filter[value.field] = new Date(value.value);
        },
    }
}
</script>
