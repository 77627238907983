<template>
    <div>
        <div class="card mb-3">
            <div class="card-header">
                <h3 class="card-title">{{ $t('export.title') }}</h3>
            </div>

            <div class="card-body">
                <div class="btn-list">
                    <button type="button" class="btn btn-primary" :class="{ 'disabled': !data }" @click.prevent="download">
                        <DownloadIcon v-if="!this.generatingExport"/>
                        <LoadingIcon v-else />

                        {{ $t('export.download') }}
                    </button>

                    <button type="button" class="btn btn-primary" :class="{ 'disabled': !data }" @click.prevent="print">
                        <PrintIcon />
                        {{ $t('export.print') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DownloadIcon from '@/components/icons/DownloadIcon.vue';
import PrintIcon from '@/components/icons/PrintIcon.vue';
import LoadingIcon from "@/components/icons/LoadingIcon";
import { getDeviceTypeName } from '@/services/device-transformer';

export default {
    name: 'Export',
    components: {
        LoadingIcon,
        DownloadIcon,
        PrintIcon,
    },

    props: {
        data: Array,
        device: Object,
        field: String,
        scale: String,
    },

    data: () => ({
        generatingExport: false
    }),

    methods: {
        download() {
            this.generatingExport = true;

            const data = [];
            for (let i = 0; i < this.data[0].length; i++) {
                data.push([
                    new Date(this.data[0][i] * 1e3).toISOString(),
                    this.data[1][i]
                ]);
            }

            const header = [
                [this.device.name, this.$t(getDeviceTypeName(this.device)) ],
                [''],
                [this.$t('export.time'), `${this.field} [${this.scale}]`],
            ];

            // Merge
            let csv = [];

            csv = csv.concat(header);
            csv = csv.concat(data);

            const body = csv.map(row => row.map(item => (typeof item === 'string' && item.indexOf(',') >= 0) ? `"${item}"`: String(item)).join(',')).join('\n');

            const element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(body));
            element.setAttribute('download', 'export.csv');

            element.style.display = 'none';
            document.body.appendChild(element);

            this.generatingExport = false;

            element.click();

            document.body.removeChild(element);
        },

        print() {
            window.print();
        }
    }
}
</script>
