<template>
    <div class="w-100">
        <div class="input-group input-group-flat">
            <input type="search" class="form-control" v-bind:disabled="loading" :value="value" :placeholder="$t('searchFilter.placeholder')" @input="$emit('filter', $event.target.value)" autocomplete="off">

            <span class="input-group-text">
                <span v-if="!value" class="input-icon-addon">
                    <SearchIcon />
                </span>
                <a v-if="value" href="#" class="link-secondary" :title="$t('searchFilter.clearSearch')" @click="$emit('filter', '')">
                    <CloseIcon />
                </a>
            </span>
        </div>
    </div>
</template>

<script>
import SearchIcon from '@/components/icons/SearchIcon';
import CloseIcon from '@/components/icons/CloseIcon';

export default {
    components: {
        CloseIcon,
        SearchIcon
    },

    props: {
        value: String,
        loading: Boolean,
    },
}
</script>
