<template>
    <div>
        <table class="table card-table table-vcenter">
            <tbody v-if="!loading">
                <tr v-for="device in paginatedDevices" :key="device.id" :class="{ 'text-muted': device.fieldsLoaded && device.fields.length === 0 }">
                    <td>
                        <div class="row">
                            <div class="col">
                                <div class="text-truncate" :title="device.name">
                                    {{ device.name }}
                                </div>
                            </div>

                            <div v-if="!device.fieldsLoading" class="col-auto align-self-center">
                                <router-link v-for="(field, index) in device.fields" :key="index" :to="{ name: 'device', params: { id: device.id, field: field.name }}">
                                    <component :is="getIcon(field.name)" :margin="false"></component>
                                </router-link>
                            </div>
                            <div v-else class="col-auto align-self-center">
                                <div class="spinner-border spinner-border-sm text-muted me-2" role="status"></div>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="i in new Array(perPage)" :key="i">
                    <td>
                        <div class="skeleton-line"></div>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="card-footer">
            <div class="row">
                <div class="col">
                    {{ $t('deviceList.footer', { count: devices.length }) }}
                </div>
                <div class="col col-auto">
                    <div class="btn-group">
                        <button class="btn btn-sm" :disabled="loading || page === 0" @click="prev">{{ $t('deviceList.prev') }}</button>
                        <button class="btn btn-sm" :disabled="loading || page >= (numberOfPages - 1)" @click="next">{{ $t('deviceList.next') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getIcon } from "@/services/field-config";

export default {
    props: {
        devices: Array,
        loading: Boolean,
    },

    data: () => ({
        perPage: 10,
        page: 0,
        total: 0,
    }),

    methods: {
        getIcon,

        next() {
            this.page = this.page + 1;
        },

        prev() {
            this.page = this.page - 1;
        }
    },

    computed: {
        paginatedDevices() {
            return this.devices.slice(this.start - 1, this.end);
        },

        start() {
            return this.total === 0 ? 0 : (this.page * this.perPage) + 1;
        },

        end() {
            return Math.min(this.total, (this.page * this.perPage) + this.perPage);
        },

        numberOfPages() {
            return Math.ceil(this.total / this.perPage);
        },
    },

    watch: {
        devices() {
            this.total = this.devices.length;
            this.page = 0;
        },

        paginatedDevices() {
            this.$emit('update-list', this.paginatedDevices);
        }
    }
}
</script>
