<template>
    <div>
        <label class="form-check mb-0">
            <input class="form-check-input" type="checkbox" :checked="value" :disabled="loading" @input="$emit('filter', $event.target.checked)">
            <span class="form-check-label">{{ $t('offlineFilter.label') }}</span>
        </label>
    </div>
</template>

<script>
export default {
    props: {
        value: Boolean,
        loading: Boolean,
    },
}
</script>
