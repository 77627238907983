<template>
    <div id="page-home">
        <div class="row">
            <div class="col-lg-7">
                <div class="card mb-3">
                    <div class="card-body">
                        <Map :devices="filteredDevices" :loading="loading" theme="large" @open-popup="getLastMeasurement"></Map>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title">{{ $t('searchFilter.title') }}</div>
                    </div>
                    <div class="card-header flex-column">
                        <div class="mb-2 w-100">
                            <SearchFilter :value="filters.search" :loading="loading" @filter="onChangeSearchFilter"></SearchFilter>
                        </div>
                        <div class="mb-3 w-100">
                            <TypeFilter :value="filters.types" :loading="loading" @filter="onChangeTypeFilter"></TypeFilter>
                        </div>
                        <div class="w-100">
                            <OfflineFilter :value="filters.offline" :loading="loading" @filter="onChangeOfflineFilter"></OfflineFilter>
                        </div>
                    </div>

                    <DeviceList :devices="filteredDevices" :loading="loading" @update-list="onListChanged" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Map from '@/components/Map';
import SearchFilter from '@/components/SearchFilter';
import OfflineFilter from '@/components/OfflineFilter';
import TypeFilter from '@/components/TypeFilter';
import DeviceList from '@/components/DeviceList';
import axios from '@/config/axios';
import { transform, TYPE_NW, TYPE_OW } from '@/services/device-transformer';
import { convertName, getAvailableInternalKeys } from '@/services/field-config';

export default {
    name: 'Home',
    components: {
        Map,
        DeviceList,
        SearchFilter,
        OfflineFilter,
        TypeFilter,
    },
    data: () => ({
        loading: true,

        devices: [],
        filters: {
            search: '',
            offline: true,
            types: {
                [TYPE_NW]: true,
                [TYPE_OW]: true,
            }
        }
    }),

    methods: {
        getAvailableInternalKeys,
        convertName,

        onChangeSearchFilter(value) {
            this.filters.search = value;
        },

        onChangeOfflineFilter(value) {
            this.filters.offline = value;
        },

        onChangeTypeFilter(value) {
            this.filters.types[value.type] = value.value;
        },

        getFields(device) {
            return new Promise((resolve, reject) => {
                if (device.fieldsLoaded) {
                    resolve(device.fields);
                }

                axios
                    .get(`/device/${device.id}/fields`)
                    .then(response => {
                        const data = response.data;

                        const filteredFields = this.getAvailableInternalKeys().filter(value => data.includes(value));
                        const fields = [];

                        filteredFields.forEach(field => {
                            const fieldName = this.convertName(field);

                            fields.push({
                                name: fieldName,
                                internalKey: field,
                                scale: this.$t(`device.channels.${device.channel}.${fieldName}.scale`),
                                lastMeasurement: null
                            });
                        });

                        resolve(fields);
                    })
                    .catch((error) => reject(error));
            });
        },

        getLastMeasurement(device) {
            return new Promise((resolve, reject) => {
                this.getFields(device).then((fields) => {
                    if (fields.length <= 1) {
                        resolve(device);
                    }

                    const filteredFields = fields.map((field) => field.internalKey);

                    axios
                        .get(`/device/${device.id}/data/timeseries/lastRecord?fields=${filteredFields.join(',')}`)
                        .then(response => {
                            const { data } = response;

                            data.forEach(row => {
                                const measurement = {
                                    date: new Date(row.timestamps[0]),
                                    value: row.values[0]
                                };

                                const fieldIndex = filteredFields.findIndex(element => {
                                    return element === row.field;
                                });

                                if (fieldIndex === -1) {
                                    return;
                                }

                                fields[fieldIndex].lastMeasurement = measurement;
                            });

                            device.fields = fields;

                            resolve(device);
                        })
                        .catch((error) => reject(error));
                });
            });
        },

        onListChanged(devices) {
            // Add fields
            devices.forEach((device) => {
                if (device.fieldsLoaded) {
                    return;
                }

                device.fieldsLoading = true;

                this.getFields(device)
                    .then((fields) => {
                        device.fieldsLoading = false;
                        device.fields = fields;
                        device.fieldsLoaded = true;
                    })
                    .catch(() => {
                        device.fieldsLoaded = true;
                        device.fieldsLoading = false;
                    });
            });
        }
    },

    computed: {
        filteredDevices() {
            let devices = this.devices;

            if (this.filters.search !== '') {
                devices = devices.filter(device => {
                    return device.name.toLowerCase().includes(this.filters.search.toLowerCase());
                });
            }

            if (this.filters.offline === false) {
                devices = devices.filter(device => {
                    return !device.offline;
                });
            }

            // nw
            if (this.filters.types[TYPE_NW] === false) {
                devices = devices.filter(device => {
                    return device.type !== TYPE_NW;
                });
            }

            // ow
            if (this.filters.types[TYPE_OW] === false) {
                devices = devices.filter(device => {
                    return device.type !== TYPE_OW;
                });
            }

            return devices;
        }
    },

    mounted() {
        axios
            .get('device', { params: { project: null, channel: null }})
            .then(response => {
                const devices = response.data.map(transform);

                devices.sort((one, two) => {
                    const nameOne = one.name.toLowerCase();
                    const nameTwo = two.name.toLowerCase();

                    if (nameOne < nameTwo) return -1;
                    if (nameOne > nameTwo) return 1;

                    return 0;
                });

                this.devices = devices;
                this.loading = false;
            })
        ;
    }
}
</script>
