<template>
    <svg :class="{ 'me-2': margin }" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 12.2539C9.23743 12.6942 8.64145 13.3738 8.30448 14.1873C7.96752 15.0008 7.9084 15.9028 8.1363 16.7533C8.3642 17.6038 8.86638 18.3554 9.56496 18.8914C10.2635 19.4275 11.1195 19.718 12 19.718C12.8805 19.718 13.7365 19.4275 14.435 18.8914C15.1336 18.3554 15.6358 17.6038 15.8637 16.7533C16.0916 15.9028 16.0325 15.0008 15.6955 14.1873C15.3586 13.3738 14.7626 12.6942 14 12.2539V5.54407C14 5.01363 13.7893 4.50493 13.4142 4.12985C13.0391 3.75478 12.5304 3.54407 12 3.54407C11.4696 3.54407 10.9609 3.75478 10.5858 4.12985C10.2107 4.50493 10 5.01363 10 5.54407V12.2539Z" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10 10H14" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    props: {
        margin: {
            type: Boolean,
            default: true,
        }
    }
}
</script>
