<template>
    <svg :class="{ 'me-2': margin }" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.3609 18.7205H3.45997V5.25163" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18.9527 6.59863L11.9106 13.3331L9.09378 10.6393L3.46014 16.0268" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18.9527 9.96584V6.59863H15.4317" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    props: {
        margin: {
            type: Boolean,
            default: true,
        }
    }
}
</script>
