import VueI18n from 'vue-i18n';

// Translations
import de from '@/translations/de';
import fr from '@/translations/fr';

const messages = { de, fr };

export default {
    create() {
        const params = new URLSearchParams(window.location.search);
        const language = params.get('language');
        const locale = language in messages ? language : 'de';

        return new VueI18n({
            locale,
            messages,
        });
    }
}
