<template>
    <div id="app" data-style-root>
        <div class="content position-relative">
            <div class="container-xl">
                <transition>
                    <keep-alive>
                        <router-view :key="$route.path"></router-view>
                    </keep-alive>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'App',
}
</script>
