<template>
    <div>
        <div v-if="loading" class="ratio ratio-21x9 card-img-top">
            <div class="skeleton-image"></div>
        </div>
        <div :data-title="title" class="plot">
            <div :class="{ 'd-none': loading }"></div>
        </div>
    </div>
</template>

<script>
import uPlot from 'uplot';
import { shortFormat, longFormat } from "@/services/date-formatter";

export default {
    name: 'Plot',
    props: {
        title: String,
        scale: String,
        label: String,
        data: Array,
        loading: Boolean,
    },

    data: () => ({
        plot: null
    }),

    computed: {
        options() {
            const w = document.querySelector(`.plot`).clientWidth;
            const h = w * 9 / 21;

            return {
                title: `${this.title} [${this.scale}]`,
                width: w,
                height: h,
                series: [
                    {
                        label: this.$t('plot.time.label'),
                        value: (u, v) => longFormat(uPlot.tzDate(new Date(v * 1e3), 'Europe/Zurich')),
                    },
                    {
                        label: this.label,
                        scale: this.scale,
                        value: (u, v) => v == null ? '-' : `${v} ${this.scale}`,
                        stroke: '#0069b4',
                        width: 1 / window.devicePixelRatio,
                        gap: -5,
                    },
                ],
                axes: [
                    {
                        values: (u, splits) => splits.map(v => shortFormat(uPlot.tzDate(new Date(v * 1e3), 'Europe/Zurich'))),
                    },
                    {
                        show: true,
                        label: '',
                        scale: this.scale,
                        value: (u, v) => v == null ? '-' : `${v} ${this.scale}`,
                        width: 1 / window.devicePixelRatio,
                    },
                ],
            };
        },
    },

    watch: {
        data() {
            if (null === this.plot) {
                this.plot = new uPlot(this.options, this.data, document.querySelector(`.plot[data-title="${this.title}"] > div`));
            }

            this.plot.setData(this.data);
        }
    }
}
</script>
