export function getDeviceState(device) {
    const now = new Date();
    const installationDate = new Date(device.installation_date);
    const uninstallationDate = new Date(device.uninstallation_date);

    let active = true;

    if (installationDate > now) {
        active = false;
    }

    if (uninstallationDate < now) {
        active = false;
    }

    const offline = !device.ok;

    return { active, offline };
}

// "Grundwassermessstellen"
export const TYPE_NW = 1;

// "Oberflächengewässermessstellen"
export const TYPE_OW = 2;

export function getDeviceType(device) {
    return device.channel === 'grundwasser' ? TYPE_NW : TYPE_OW;
}

export function getDeviceTypeName(device) {
    const type = getDeviceType(device);

    if (type === TYPE_NW) {
        return 'device.types.nw';
    }

    return 'device.types.ow';
}

export function transform(device) {
    const { active, offline } = getDeviceState(device);

    const now = new Date();
    const uninstalled = new Date(device.uninstallation_date);

    return {
        id: device.id,
        name: device.name,
        type: getDeviceType(device),
        coordinates: [device.location.latitude, device.location.longitude],
        active,
        offline,
        public: device.public,
        installed: new Date(device.installation_date),
        uninstalled: now < uninstalled ? null : uninstalled,
        channel: device.channel,

        fieldsLoaded: false,
        fieldsLoading: false,
        fields: [],
    };
}
