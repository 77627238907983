import Home from '@/pages/Home';
import Location from '@/pages/Device';
import NotFound from '@/pages/NotFound'

export default [
    {
        name: 'home',
        path: '/',
        component: Home
    },
    {
        name: 'device',
        path: '/device/:id/:field',
        component: Location,
    },
    {
        name: '404',
        path: '*',
        component: NotFound
    }
];
