<template>
    <div class="card mb-3">
        <div class="card-body">
            <h4 class="card-title">{{ $t('device.lastMeasurement.title') }}</h4>

            <div class="mb-2">
                <small class="text-muted text-truncate">
                    {{ $t(`device.channels.${device.channel}.${field.name}.name`) }}
                </small>
                <div class="text-body d-block text-truncate">
                    <div v-if="loading" class="skeleton-line"></div>

                    <div v-if="measurement">
                        <span>{{ measurement.value }} {{ field.scale }}</span>&nbsp;
                        <span class="text-muted">({{ longFormat(measurement.date) }})</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/config/axios'
import { longFormat } from "@/services/date-formatter";

export default {
    name: 'LastMeasurement',
    props: {
        device: Object,
        field: Object,
    },
    data: () => ({
        loading: false,
        measurement: null
    }),

    methods: {
        longFormat,
    },

    mounted() {
        this.loading = true;

        axios
            .get(`/device/${this.device.id}/data/timeseries/lastRecord?fields=${this.field.internalKey}`)
            .then(response => {
                const { data } = response;

                if (!data[0]) {
                    return;
                }

                const fieldData = data[0];

                if (!fieldData.timestamps[0] || !fieldData.values[0]) {
                    return;
                }

                this.measurement = {
                    date: new Date(fieldData.timestamps[0]),
                    value: fieldData.values[0],
                };

                this.loading = false;
            });
    },
};
</script>
