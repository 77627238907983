import Vue from 'vue';
import TemperatureIcon from '@/components/icons/TemperatureIcon';
import ChartIcon from '@/components/icons/ChartIcon';
import LightningIcon from '@/components/icons/LightningIcon';
// import WaveIcon from "@/components/icons/WaveIcon";

Vue.use(TemperatureIcon);
Vue.use(ChartIcon);
Vue.use(LightningIcon);
// Vue.use(WaveIcon);

const config = {
    'pegel_m': {
        icon: ChartIcon,
        internalKey: 'pegel.m'
    },

    'pegel_muM': {
        icon: ChartIcon,
        internalKey: 'pegel.muM'
    },

    'temperatur_c': {
        icon: TemperatureIcon,
        internalKey: 'temperatur.c'
    },

    'el_leit_muScm': {
        icon: LightningIcon,
        internalKey: 'el.leit.muScm'
    },

    'el_leit_mScm': {
        icon: LightningIcon,
        internalKey: 'el.leit.mScm'
    }
}

export function getIcon(field) {
    return config[field].icon;
}

export function getAvailableFields() {
    return Object.keys(config);
}

export function getAvailableInternalKeys() {
    return Object.values(config).map(field => field.internalKey);
}

export function convertName(name) {
    return name.replaceAll('.', '_');
}

export function reverseConvertName(name) {
    return name.replaceAll('_', '.');
}
