<template>
    <div>
        <div class="card mb-3">
            <div class="card-header">
                <h3 class="card-title">Manuelle Eingabe</h3>
            </div>

            <div class="card-body">
                <date-picker mode="dateTime" v-model="fromModel" locale="de" is24hr :min-date="device.installed" :max-date="to">
                    <template v-slot="{ inputValue, inputEvents }">
                        <div class="input-icon mb-2">
                            <input id="from" class="form-control mb-2" :value="inputValue" v-on="inputEvents" />

                            <span class="input-icon-addon">
                                <CalendarIcon />
                            </span>
                        </div>
                    </template>
                </date-picker>

                <date-picker mode="dateTime" v-model="toModel" locale="de" is24hr :max-date="device.uninstalled ? device.uninstalled : new Date()" :min-date="from">
                    <template v-slot="{ inputValue, inputEvents }">
                        <div class="input-icon mb-2">
                            <input id="to" class="form-control mb-2" :value="inputValue" v-on="inputEvents" />

                            <span class="input-icon-addon">
                                <CalendarIcon />
                            </span>
                        </div>
                    </template>
                </date-picker>
            </div>
        </div>

    </div>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import CalendarIcon from "@/components/icons/CalendarIcon";

export default {
    name: 'DataFilter',
    components: {CalendarIcon, DatePicker },

    props: {
        device: Object,
        from: Date,
        to: Date,
    },

    computed: {
        fromModel: {
            get () { return this.from },
            set (value) { this.$emit('changeFilter', { field: 'from', value }) },
        },

        toModel: {
            get () { return this.to },
            set (value) { this.$emit('changeFilter', { field: 'to', value }) },
        },
    }
}
</script>
