export function dateFormat(input) {
    const format = new Intl.DateTimeFormat('de-CH',  {
        timeZone: 'Europe/Zurich',
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    }).format;

    return format(input);
}

export function shortFormat(input) {
    const format = new Intl.DateTimeFormat('de-CH',  {
        timeZone: 'Europe/Zurich',
        month: '2-digit',
        day: '2-digit',
    }).format;

    return format(input);
}

export function longFormat(input) {
    const format = new Intl.DateTimeFormat('de-CH',  {
        timeZone: 'Europe/Zurich',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    }).format;

    return format(input);
}
